const reviewsSliderMobile = () => {

  $(document).ready(function(){
    $('.reviews__slider_mobile').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '0',
      vertical: true,
      verticalSwiping: true,
      adaptiveHeight: true,
      arrows: true

    });
  }); 

};

export default reviewsSliderMobile;

