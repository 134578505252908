"use strict";

import reviewsSlider from "./modules/reviewsSlider.js";
import reviewsSliderMobile from "./modules/reviewsSliderMobile.js";
import hamburger from "./modules/hamburger.js";
import headerBg from "./modules/headerBg.js";


try {
    reviewsSlider();
} catch (error) {
    
}

try {
  reviewsSliderMobile();
} catch (error) {
  
}

hamburger();
headerBg();




