const headerBg = () => {
    const header = document.querySelector('.header'),
          headerHeight = header.style.height;
    

    document.addEventListener('scroll', () => {
        const windowScroll = window.scrollY || document.documentElement.scrollTop;
        if (window.innerWidth > 640 && windowScroll > headerHeight) {
            header.style.background = 'linear-gradient( 45deg, rgba(0, 0, 0, .9), rgba(155, 16, 20, .9))';
        } else {
            header.style.background = 'initial';
        }
    });

};

export default headerBg;

